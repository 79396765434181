import { TextFormats } from 'components/Text'
import { classNamesFsExclude } from 'utils/css'

export const title = 'Pod Consumption'

type Estimate = {
  failure: TextFormats
  success: TextFormats
}
export const estimateMessage: Estimate = {
  failure: { content: 'An estimate for puffs per pod is not available.' },
  success: [
    { content: 'You get approximately ' },
    { className: classNamesFsExclude(), content: '%{estimate} puffs', tagName: 'strong' },
    { content: ' per pod.' },
  ],
}

type FormatEstimate = (min: number, max: number) => { estimate: string }
export const formatEstimate: FormatEstimate = (min, max) => (
  { estimate: `${min}-${max}` }
)

export const heading: TextFormats = [
  { content: 'You puffed about ' },
  { className: classNamesFsExclude(), content: '%{pods}', tagName: 'span' },
  { content: ' per day.' },
]

type FormatHeading = (pods: number) => { pods: string }
export const formatHeading: FormatHeading = (pods) => (
  { pods: (pods === 1) ? '1 pod' : `${pods} pods` }
)

export const overline: TextFormats = { content: '(Average over the last 7 days)' }
