import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import { useTrackClick } from 'modules/AnalyticsProvider/hooks'
import { Event } from 'analytics-events'

export interface UnstyledInputProps extends ComponentPropsWithoutRef<'input'> {
  clickEvent?: Event
}

export const UnstyledInput = forwardRef<
  HTMLInputElement,
  UnstyledInputProps
>(({ clickEvent, onClick, ...rest }, ref?) => {
  const { onClickWithTracking }
    = useTrackClick<HTMLInputElement>(onClick, clickEvent)

  return (
    <input
      {...rest}
      ref={ref}
      onClick={onClickWithTracking}
    />
  )
})

UnstyledInput.displayName = 'UnstyledInput'
