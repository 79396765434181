import React, { FC, useState, useMemo } from 'react'
import { startOfToday } from 'date-fns'
import {
  DateRangePicker,
  DateRangeDirection,
  InsightsCard,
  InsightsCardProps,
} from '../../components'
import { SlidingTabs } from 'components/SlidingTabs'
import styles from './PuffHistory.module.scss'
import { Box } from 'components/Box'
import {
  PuffHistoryBarchart,
  PuffHistoryFormat,
} from '../../charts/PuffHistoryBarchart'
import { WebAppScreens, WebAppInsights, Event } from 'analytics-events'

// config
const PUFF_HISTORY_CHART_HEIGHT = 272

// data
export const puffHistoryTitle = 'Puff History'
const AVAILABLE_FORMATS: PuffHistoryFormat[] = [
  PuffHistoryFormat.SevenDay,
  PuffHistoryFormat.Month,
]
const FORMAT_CLICK_EVENTS = [
  WebAppScreens.UsageInsights.ClickSevenDays_PuffHistory(),
  WebAppScreens.UsageInsights.ClickMonth_PuffHistory(),
]
const DATE_RANGE_CLICK_EVENTS = {
  [PuffHistoryFormat.SevenDay]: {
    [DateRangeDirection.Next]: WebAppScreens.UsageInsights.ClickDateForward_SevenDays_PuffHistory(),
    [DateRangeDirection.Prev]: WebAppScreens.UsageInsights.ClickDatePrior_SevenDays_PuffHistory(),
  },
  [PuffHistoryFormat.Month]: {
    [DateRangeDirection.Next]: WebAppScreens.UsageInsights.ClickDateForward_Month_PuffHistory(),
    [DateRangeDirection.Prev]: WebAppScreens.UsageInsights.ClickDatePrior_Month_PuffHistory(),
  },
}

// types
export type PuffHistoryProps = Omit<InsightsCardProps, 'title'>

export const PuffHistory: FC<PuffHistoryProps> = ({ loading, ...rest }) => {
  // state
  const [isValid, setIsValid] = useState<boolean>(null)
  const [
    chartFormat,
    setChartFormat,
  ] = useState<PuffHistoryFormat>(PuffHistoryFormat.SevenDay)
  const [startDate, setStartDate] = useState<Date>(startOfToday())

  // events
  const puffHistoryEvent = useMemo<Event>(() => {
    if (isValid === null) return null
    return WebAppInsights.PuffHistoryEvent({ isValid })
  }, [isValid])

  // InsightsCard controls
  const puffHistoryControls = (
    <Box
      className={styles.puffHistoryControls}
      padding={{ top: 'tight' }}
    >
      <SlidingTabs<PuffHistoryFormat>
        clickEvents={FORMAT_CLICK_EVENTS}
        id='puffHistory'
        onClickTab={setChartFormat}
        tabs={AVAILABLE_FORMATS}
      />
      <div className={styles.datePickers}>
        {AVAILABLE_FORMATS.map((format) => (
          <div key={format} className={styles.date}>
            {chartFormat === format && (
              <DateRangePicker
                clickEvents={DATE_RANGE_CLICK_EVENTS[format]}
                onPickDate={setStartDate}
                type={format}
              />
            )}
          </div>
        ))}
      </div>
    </Box>
  )

  return (
    <InsightsCard
      cardEvent={puffHistoryEvent}
      isValid={isValid}
      controls={puffHistoryControls}
      title={puffHistoryTitle}
      loading={loading || isValid === null}
      {...rest}
    >
      <PuffHistoryBarchart
        chartFormat={chartFormat}
        height={PUFF_HISTORY_CHART_HEIGHT}
        padding={{ top: 'baseLoose' }}
        setIsValid={setIsValid}
        startDate={startDate}
      />
    </InsightsCard>
  )
}
