import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { classNamesFsExclude } from 'utils/css'

export interface DailyPuffTrendlineProps extends BoxProps {
  height: number
  setHasAverage(hasAverage: boolean): void
  setIsValid(isValid: boolean): void
  setPuffCount(puffCount: number): void
}

export const DailyPuffTrendline: FC<DailyPuffTrendlineProps> = ({
  height,
  setHasAverage,
  setIsValid,
  setPuffCount,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachPuffsTodayAdapter(
      currentCanvas,
      (puffCount: number, isValid: boolean, hasAverage: boolean) => {
        setPuffCount(puffCount)
        setIsValid(isValid)
        setHasAverage(hasAverage)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
