import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from '../Tile.module.scss'
import { Text, TextColor } from 'components/Text'
import { classNames } from 'utils/css'

export interface InfoProps extends ComponentPropsWithoutRef<'div'> {
  content: string
  contentStyle?: {
    className?: string
    color?: TextColor
    typography?: 'bodyBase' | 'bodyBaseBold'
  }
  title: string
}

export const Info: FC<InfoProps> = ({
  className,
  content,
  contentStyle,
  title,
  ...rest
}) => (
  <div className={classNames(styles.Info, className)} {...rest}>
    <Text
      color='ink|base|50'
      message={{ content: title }}
      tagName='h4'
      typography='overlineBase'
    />
    <Text
      className={contentStyle?.className}
      color={contentStyle?.color || 'ink|base|100'}
      message={{ content: content }}
      tagName='p'
      typography={contentStyle?.typography || 'bodyBase'}
    />
  </div>
)
