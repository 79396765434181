import {
  ListType,
  ListItemType,
  DeviceType,
} from './types'
import { InterfaceType, WarrantyInterface } from 'types/gadget'
import { TextModalProps } from 'modules/ReoverlayModal/components/TextModal'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'

// Modals

export const ClearBrowserHistoryModal: TextModalProps = {
  description: [
    { content: 'As part of this test, we want to show you what happens when you clear your browser history.' },
    { content: 'This button simulates what deleting your browser history would do to the data stored in the JUUL App. Your browser history outside of this app won’t be affected.' },
    { content: 'Please only click on this button when asked to do so.' },
  ],
  heading: { content: 'Please wait to run this simulation until asked to do so.' },
  primaryButton: { content: 'Simulate Browser Clearing Event' },
}

export const ClearDeviceDataModal: TextModalProps = {
  description: [
    { content: 'As part of this test, we want to show you what happens when you clear the data from your JUUL2.' },
    { content: 'This button simulates how clearing the data stored on your JUUL2 would behave in the JUUL App. Your data would still exist on the cloud if you opt-in to automatic backups.' },
    { content: 'Please only click on this button when asked to do so.' },
  ],
  heading: { content: 'Please wait to run this simulation until asked to do so.' },
  primaryButton: { content: 'Simulate Clearing Data from JUUL2' },
}

export const SwapFirmwareImageModal: TextModalProps = {
  description: [
    { content: 'Swap to the alternate/previous firmware image on a JUUL device.' },
    { content: 'This process includes a reboot of the JUUL device which will cause it to disconnect momentarily.' },
  ],
  heading: { content: 'Swap firmware image' },
  primaryButton: { content: 'Proceed', primary: 'red' },
  secondaryButton: { content: 'Done' },
  stackControls: true,
}

export const DisableCloudSyncModal: TextModalProps = {
  description: [
    { content: 'Your puff data will be removed from your account and will no longer be synced across your devices. Your data will remain on this device until the browser’s cache is cleared.' },
  ],
  heading: { content: 'Turning off automatic backups' },
  icon: { name: 'icon/48x48/service_outage' },
  primaryButton: { content: 'Turn Off Automatic Backups', primary: 'red' },
  secondaryButton: { content: 'Never Mind' },
  stackControls: true,
}

export const SyncingErrorModal: TextModalProps = {
  align: 'center',
  description: [
    { content: 'There was a problem syncing with the cloud. Please check your internet connection and try again.' },
  ],
  heading: { content: 'Syncing error' },
  icon: { name: 'icon/48x48/alert_outlined' },
}

export const ToggleOffErrorModal: TextModalProps = {
  align: 'center',
  description: [
    { content: 'Please check your internet connection and try again. If the problem persists, please try again after signing out and signing back in.' },
  ],
  heading: { content: 'Error disabling automatic backups' },
  icon: { name: 'icon/48x48/alert_outlined' },
}

// Toggle Line Items

const cloudSyncMessage = 'Securely sync your usage data to your account so that you don’t lose it when you open the JUUL App from a different computer or delete your browsing history. Backups are fully encrypted so Juul Labs will never have access to your usage data.'

export const CloudSync: ListItemType = {
  actionType: 'toggle',
  altMessage: [
    { content: cloudSyncMessage },
    { tagName: 'br' },
    { content: 'Last backup: %{lastBackup}' },
  ],
  defaultMessage: { content: cloudSyncMessage },
  key: 'CloudSync',
  primaryContent: 'Automatic Backups',
}

export const ChargedBeepable: ListItemType = {
  actionType: 'toggle',
  key: 'ChargedBeepable',
  primaryContent: 'Beep at full charge',
}

// Line Items

export const WarrantyExpired: ListItemType = {
  actionType: 'other',
  icon: null,
  key: 'WarrantyExpired',
  primaryContent: 'Warranty Expired',
}

export const WarrantyActive: ListItemType = {
  actionType: 'link',
  key: 'WarrantyActive',
  primaryContent: 'Warranty Active',
  secondaryContent: 'Submit a claim',
}

export const WarrantyInactive: ListItemType = {
  actionType: 'link',
  key: 'WarrantyInactive',
  link: MobilecloudLink.WarrantyRegistration,
  primaryContent: 'Warranty Inactive',
  secondaryContent: 'Register',
}

export const Connect: ListItemType = {
  actionType: 'other',
  icon: 'icon/24x24/add_device',
  key: 'Connect',
  primaryContent: 'Connect a new device',
}

export const Disconnect: ListItemType = {
  actionType: 'other',
  key: 'Disconnect',
  primaryContent: 'Disconnect',
}

export const Reconnect: ListItemType = {
  actionType: 'other',
  key: 'Reconnect',
  primaryContent: 'Reconnect',
}

export const Authenticate: ListItemType = {
  actionType: 'other',
  key: 'Authenticate',
  primaryContent: 'Re-authenticate',
}

export const ClearBrowserHistory: ListItemType = {
  actionType: 'other',
  icon: 'icon/24x24/refresh',
  key: 'ClearBrowserHistory',
  primaryContent: 'Simulation: Clear Browser History',
}

export const SwapFirmwareImage: ListItemType = {
  actionType: 'other',
  key: 'SwapFirmwareImage',
  primaryContent: 'Swap firmware image',
}

export const UpdateFirmware: ListItemType = {
  actionType: 'other',
  altPrimaryContent: 'Firmware update available',
  key: 'UpdateFirmware',
  primaryContent: 'Firmware %{version} is up to date',
}

export const Forget: ListItemType = {
  actionType: 'other',
  key: 'Forget',
  primaryContent: 'Forget this device',
}

export const SignOut: ListItemType = {
  actionType: 'other',
  icon: 'icon/24x24/exit',
  key: 'SignOut',
  primaryContent: 'Sign Out',
}

export const FAQs: ListItemType = {
  actionType: 'link',
  key: 'FAQs',
  link: MobilecloudLink.FAQs,
  primaryContent: 'FAQs',
}

export const GiveFeedback: ListItemType = {
  actionType: 'other',
  key: 'GiveFeedback',
  primaryContent: 'Give feedback',
}

export const PrivacyPolicy: ListItemType = {
  actionType: 'link',
  key: 'PrivacyPolicy',
  link: MobilecloudLink.PrivacyPolicy,
  primaryContent: 'Privacy policy',
}

export const TermsOfService: ListItemType = {
  actionType: 'link',
  key: 'TermsOfService',
  link: MobilecloudLink.TermsOfService,
  primaryContent: 'Terms of service',
}

export const DebugData: ListItemType = {
  actionType: 'other',
  icon: 'icon/24x24/upload',
  key: 'DebugData',
  primaryContent: 'Upload debug information',
}

// Settings Sections

export const Feedback: ListType = {
  listContent: [GiveFeedback],
  listKey: 'Feedback',
  listTitle: 'Feedback',
}

export const Account: ListType = {
  listContent: [CloudSync, SignOut],
  listKey: 'Account',
  listTitle: 'Account',
}

export const Support: ListType = {
  listContent: [FAQs],
  listKey: 'Support',
  listTitle: 'Support',
}

export const About: ListType = {
  listContent: [TermsOfService, PrivacyPolicy],
  listKey: 'About',
  listTitle: 'About',
}

// this is a List for EUT tools
export const Testing: ListType = {
  listContent: [DebugData, ClearBrowserHistory],
  listKey: 'Testing',
  listTitle: 'Testing',
}

// Device Settings Options & Device Card Data

export const Devices: ListType = {
  listContent: [Connect],
  listKey: 'Devices',
  listTitle: 'Your Devices',
}

export const DeviceCardData: DeviceType = {
  chargedBeepableToggle: ChargedBeepable,
  primaryButton: {
    [InterfaceType.Disconnected]: Reconnect,
    [InterfaceType.Unauthenticated]: Authenticate,
    default: Disconnect,
  },
  secondaryButton: { default: Forget },
  swapFirmware: SwapFirmwareImage,
  tag: {
    [InterfaceType.Reconnecting]: { content: 'Reconnecting%{ellipsis}' },
    [InterfaceType.Unauthenticated]: { content: 'Awaiting authentication%{ellipsis}' },
    default: null,
  },
  updateFirmware: UpdateFirmware,
  warrantyContent: {
    [WarrantyInterface.Active]: WarrantyActive,
    [WarrantyInterface.Expired]: WarrantyExpired,
    [WarrantyInterface.Inactive]: WarrantyInactive,
  },
}

export const maxDeviceNameLength = 15
