const MobilecloudPath = {
  Account: '/link/account',
  AccountMembership: '/link/account-membership',
  FAQs: '/link/faqs',
  PrivacyPolicy: '/link/privacy-policy',
  Shop: '/link/shop',
  SignIn: '/sso/signin',
  SignUp: '/sso/signup',
  StoreLocator: '/link/store-locator',
  Subscription: '/link/subscription',
  TermsOfService: '/link/terms-and-conditions',
  WarrantyClaim: '/link/claim-warranty',
  WarrantyRegistration: '/link/register-product',
} as const

/**
 * Mobilecloud links for redirecting users. This allows the app backend to
 * maintain common redirect links on behalf of client platforms.
 */

export const MobilecloudLink = Object.keys(MobilecloudPath).reduce((links, key) => ({
  ...links,
  [key]: `${process.env.NEXT_PUBLIC_MOBILECLOUD_URL}${MobilecloudPath[key]}`,
}), MobilecloudPath)

export type MobilecloudLinkType = typeof MobilecloudLink[keyof typeof MobilecloudLink]

export const getWarrantyClaimLink = (productType: string, productId: string) =>
  `${MobilecloudLink.WarrantyClaim}?topic=${productType}&productId=${productId}`
