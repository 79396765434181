import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { TrendDirection } from '../../config'
import { classNamesFsExclude } from 'utils/css'

export interface WeeklyPuffsBarchartProps extends BoxProps {
  height: number
  setIsValid(isValid: boolean): void
  setTrend(trend: TrendDirection): void
}

export const WeeklyPuffsBarchart: FC<WeeklyPuffsBarchartProps> = ({
  height,
  setIsValid,
  setTrend,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachShortTermTrend(
      currentCanvas,
      (trend: TrendDirection, isValid: boolean) => {
        setTrend(trend)
        setIsValid(isValid)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
