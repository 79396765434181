import React, { FC, useState, useMemo } from 'react'
import { Box } from 'components/Box'
import { InsightsCard, InsightsCardProps } from '../../components'
import { SlidingTabs } from 'components/SlidingTabs'
import {
  PodHistoryDonut,
  PodHistoryPeriod,
  getPodHistoryDonutChartState,
} from '../../charts/PodHistoryDonut'
import { WebAppScreens, WebAppInsights, Event } from 'analytics-events'

// config
const POD_HISTORY_CHART_MAX_DIAMETER = 360

// data
export const podHistoryTitle = 'Pod History'
const AVAILABLE_PERIODS: PodHistoryPeriod[] = [
  PodHistoryPeriod.SevenDays,
  PodHistoryPeriod.ThirtyDays,
  PodHistoryPeriod.NinetyDays,
]
const PERIOD_CLICK_EVENTS = [
  WebAppScreens.UsageInsights.ClickSevenDays_PodHistory(),
  WebAppScreens.UsageInsights.ClickThirtyDays_PodHistory(),
  WebAppScreens.UsageInsights.ClickNinetyDays_PodHistory(),
]

// types
export type PodHistoryProps = Omit<InsightsCardProps, 'title'>

export const PodHistory: FC<PodHistoryProps> = ({ ...rest }) => {
  // state
  const [hasPods, setHasPods] = useState<boolean>(null)
  const [hasUnknownFlavor, setHasUnknownFlavor] = useState<boolean>(null)
  const [
    timePeriod,
    setTimePeriod,
  ] = useState<PodHistoryPeriod>(PodHistoryPeriod.SevenDays)

  // events
  const podHistoryEvent = useMemo<Event>(() => {
    if (hasPods === null || hasUnknownFlavor === null) return null

    return WebAppInsights.PodHistoryEvent({
      chartState: getPodHistoryDonutChartState(hasPods, hasUnknownFlavor),
      isValid: true,
    })
  }, [hasPods, hasUnknownFlavor])

  const podHistoryControls = (
    <Box padding={{ top: 'tight' }}>
      <SlidingTabs<PodHistoryPeriod>
        clickEvents={PERIOD_CLICK_EVENTS}
        id='podHistory'
        onClickTab={setTimePeriod}
        tabs={AVAILABLE_PERIODS}
      />
    </Box>
  )

  return (
    <InsightsCard
      cardEvent={podHistoryEvent}
      controls={podHistoryControls}
      title={podHistoryTitle}
      {...rest}
    >
      <PodHistoryDonut
        padding={{ top: 'baseLoose' }}
        maxDiameter={POD_HISTORY_CHART_MAX_DIAMETER}
        setHasPods={setHasPods}
        setHasUnknownFlavor={setHasUnknownFlavor}
        timePeriod={timePeriod}
      />
    </InsightsCard>
  )
}
