import React, { FC, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect, useSize, useThrottledValue } from 'hooks'
import styles from './MostRecent.module.scss'
import { Text } from 'components/Text'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { LineItem } from '@juullabs/react-components'
import { orderStatusText } from './localization'
import { OrderStatus } from 'modules/MobilecloudAPI/types'
import { classNamesFsExclude } from 'utils/css'

type CardsToShow = {
  maxCardsShowable: number
  numRemainingCards: number
}

const CARD_WIDTH = 70
const OVERFLOW_CARD_WIDTH = 64
const CARD_GAP = 4

const calculateCardsToShow = (
  displayWidth: number,
  numberOfCards: number,
): CardsToShow => {
  const adjustedWidth = displayWidth + CARD_GAP
  const numCardsShowable = Math.floor(adjustedWidth / CARD_WIDTH)
  const remainingSpace = adjustedWidth % CARD_WIDTH

  if (numCardsShowable >= numberOfCards) {
    return {
      maxCardsShowable: numberOfCards,
      numRemainingCards: 0,
    }
  } else if (numberOfCards - numCardsShowable === 1
    || remainingSpace < OVERFLOW_CARD_WIDTH) {
    return {
      maxCardsShowable: numCardsShowable - 1,
      numRemainingCards: numberOfCards - numCardsShowable + 1,
    }
  } else {
    return {
      maxCardsShowable: numCardsShowable,
      numRemainingCards: numberOfCards - numCardsShowable,
    }
  }
}

export type Item = {
  display_text: string
  image: string
  product_name: string
  quantity?: number
}

export interface MostRecentProps extends TileProps {
  contents: Item[]
  date: string
  number: string
  status: OrderStatus
}

export const MostRecent: FC<MostRecentProps> = ({
  contents,
  date,
  loading = false,
  number,
  status,
  ...rest
}) => {
  const contentRef = useRef<(HTMLDivElement)>()
  const size = useSize(contentRef)
  const throttledSize = useThrottledValue({ throttleMs: 300, value: size })

  const [{ maxCardsShowable, numRemainingCards }, setCardsToShow]
    = useState<CardsToShow>({ maxCardsShowable: 0, numRemainingCards: 0 })

  useIsomorphicLayoutEffect(() => {
    if (loading) return

    setCardsToShow(calculateCardsToShow(
      contentRef.current?.offsetWidth,
      contents?.length,
    ))

  }, [loading, throttledSize?.width, Boolean(size)])

  return (
    <Tile className={styles.MostRecent} loading={loading} {...rest}>
      <Tile.Heading
        color='jade|base|100'
        content='Most Recent Order'
      />
      <Text
        margin={{ bottom: 'base' }}
        message={{ content: orderStatusText[status] }}
        tagName='p'
        typography='bodyBig'
      />
      <div className={styles.details}>
        <Tile.Info
          content={date}
          contentStyle={{ className: classNamesFsExclude() }}
          title='Order Date'
        />
        <Tile.Info
          content={number}
          contentStyle={{ className: classNamesFsExclude() }}
          title='Order #'
        />
      </div>
      <div
        className={classNamesFsExclude(styles.content)}
        ref={contentRef}
      >
        {contents.slice(0, maxCardsShowable)
          .map(({ display_text, image, product_name, quantity }, i) => (
            <div key={i} title={`${product_name}, ${display_text}`}>
              <LineItem
                item={{
                  imageUrl: image,
                  name: product_name,
                  quantity,
                }}
              />
            </div>
          ))}
        {Boolean(numRemainingCards) && (
          <div className={styles.overflow}>
            +{numRemainingCards}
          </div>
        )}
      </div>
    </Tile>
  )
}
