import { Event } from 'analytics-events'
import { PuffHistoryFormat } from '../../charts/PuffHistoryBarchart'

export enum DateRangeDirection {
  Next = 'next',
  Prev = 'prev',
}

export type DateRangePickerProps = {
  className?: string
  clickEvents?: {
    [DateRangeDirection.Next]: Event
    [DateRangeDirection.Prev]: Event
  }
  onClickNavigation?(direction: string): void
  onPickDate?(start: Date): void
  startDate?: Date
  type?: PuffHistoryFormat
}

export type Slide = {
  id: number
  label: string
  start: Date
}
