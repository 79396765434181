import React, { FC, useState } from 'react'
import { Notification } from 'components/Notification'
import { SlidingTabs } from 'components/SlidingTabs'
import { InsightsCard } from '../../../../components'
import { MockPuffsForm } from '../MockPuffsForm/MockPuffsForm'
import { TabValues, MockPuffsCardProps } from '../../types'
import { Box } from 'components/Box'

export const MockPuffsCard: FC<MockPuffsCardProps> = ({
  notifications,
  onSubmit,
  tabs,
  title,
  ...rest
}) => {
  const [tab, setTab] = useState<TabValues>(tabs[0])

  const mockPuffsControls = (
    <Box padding={{ top: 'tight' }}>
      <SlidingTabs<TabValues>
        id='mockPuffs'
        onClickTab={setTab}
        tabs={tabs}
      />
    </Box>
  )

  return (
    <InsightsCard
      controls={mockPuffsControls}
      title={title}
    >
      <Box padding={{ bottom: 'baseLoose', top: 'base' }}>
        <Notification
          content={notifications.message[tab]}
          heading={notifications.title[tab] ?? notifications.title.default}
          type={notifications.type[tab] ?? notifications.type.default}
          icon={notifications.icon[tab] ?? notifications.icon.default}
        />
      </Box>
      <MockPuffsForm
        onSubmit={onSubmit}
        tab={tab}
        {...rest}
      />
    </InsightsCard>
  )
}
