import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { classNamesFsExclude } from 'utils/css'

export interface DailyPuffTreemapProps extends BoxProps {
  maxHeight: number
  minHeight?: number
  setIsValid(isValid): void
  setMaxPuffDay(min: string): void
  setMinPuffDay(max: string): void
}

const DEFAULT_MIN_CHART_HEIGHT = 200
const GOLDEN_RATIO = '317811/196418' // ~1.61803398874

export const DailyPuffTreemap: FC<DailyPuffTreemapProps> = ({
  maxHeight,
  minHeight = DEFAULT_MIN_CHART_HEIGHT,
  setIsValid,
  setMaxPuffDay,
  setMinPuffDay,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachPuffAverageByDayAdapter(
      currentCanvas,
      (min: string, max: string, isValid: boolean) => {
        setMinPuffDay(min)
        setMaxPuffDay(max)
        setIsValid(isValid)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          aspectRatio: GOLDEN_RATIO,
          display: 'block',
          maxHeight: `${maxHeight}px`,
          minHeight: `${minHeight}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
