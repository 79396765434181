import React, { FC, useEffect, useRef, useState } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import Chai from 'types/chai'
import { Box, BoxProps } from 'components/Box'
import { classNamesFsExclude } from 'utils/css'

export enum PuffHistoryFormat {
  Month='month',
  SevenDay='7 days',
}

export interface PuffHistoryBarchartProps extends BoxProps {
  chartFormat: PuffHistoryFormat
  height: number
  setIsValid(isValid: boolean): void
  startDate: Date
}

export const PuffHistoryBarchart: FC<PuffHistoryBarchartProps> = ({
  chartFormat,
  height,
  setIsValid,
  startDate,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [insightUiState, setInsightUiState] =
    useState<Chai.SummaryInsightUiState>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    setInsightUiState(usageInsights?.attachPuffHistoryAdapter(
      currentCanvas,
      setIsValid,
    ))

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  useEffect(() => {
    insightUiState?.setMode(chartFormat)
    insightUiState?.setDate(startDate)
  }, [insightUiState, chartFormat, startDate])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
