import { TextFormats } from 'components/Text'
import { classNamesFsExclude } from 'utils/css'

export const title = 'Puffs So Far'

export const description: TextFormats = [
  { content: 'So far, you have taken ' },
  { className: classNamesFsExclude(), content: '%{puffs} puffs', tagName: 'strong' },
  { content: '.' },
]
