import classnames from 'classnames'
import { getIsPreProdEnvironment } from './app'

/**
 * Get a variation's className
 *
 * @param {string} name
 * @param {string} value
 * @returns - the corresponding className for the variation
 */
export const variationName = (name: string, value: string) =>
  `${name}${value.charAt(0).toUpperCase()}${value.slice(1)}`

/**
 * Get a variation's className using numbers
 *
 * @param {string} name
 * @param {number} value
 * @returns - the corresponding className for the variation
 */
export const variationSize = (name: string, value: number) =>
  `${name}${value}`

export const classNames = classnames

/**
 * Function to add fs-exclude class to classNames if not in pre-prod environment
 *
 * @param {classnames.ArgumentArray} args
 * @returns - string of classNames including fs-exclude if not in pre-prod environment
 */
export const classNamesFsExclude = (...args: Parameters<typeof classNames>) =>
  classNames(...args, !getIsPreProdEnvironment() && 'fs-exclude')
