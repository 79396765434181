import React, { useState, useEffect, MouseEvent } from 'react'
import styles from './SlidingTabs.module.scss'
import { UnstyledInput } from 'components/UnstyledPrimitives'
import { Event } from 'analytics-events'

export type SlidingTabsProps<T extends string | number> = {
  // click events for each tab in the same order as values
  clickEvents?: Event[]
  // default/initial value for the tab
  defaultTab?: T
  // unique identifier for the form
  id: string
  // callback function for when a tab is clicked
  onClickTab(tab: T): void
  // values for each tab in the order they should appear
  tabs: T[]
}

export const SlidingTabs = <T extends string | number>({
  clickEvents,
  defaultTab,
  id,
  onClickTab,
  tabs,
}: SlidingTabsProps<T>) => {
  const formId = `sliding-tabs-${id}`
  const tabWidth = 100.00 / tabs.length
  const gliderOffset = 4.00 / tabs.length
  const defaultValueIndex = defaultTab ? tabs.indexOf(defaultTab) : 0
  const initialGliderTranslation = `${defaultValueIndex * 100}%`
  const [gliderTranslation, setGliderTranslation]
    = useState<string>(initialGliderTranslation)

  const handleSelection = (e: MouseEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value as T

    onClickTab(value)
    setGliderTranslation(`${tabs.indexOf(value) * 100}%`)
  }

  useEffect(() => {
    setGliderTranslation(initialGliderTranslation)
  }, [tabs.length])

  return (
    <form className={styles.SlidingTabs} id={formId}>
      <fieldset className={styles.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={`${tab}-${index}`}
            className={styles.tab}
            style={{ width: `${tabWidth}%` }}
          >
            <UnstyledInput
              className={styles.tabInput}
              clickEvent={clickEvents?.[index]}
              defaultChecked={index === defaultValueIndex}
              form={formId}
              id={`${formId}-radio-${tab}-${index}`}
              name='tabs'
              onClick={handleSelection}
              type='radio'
              value={tab}
            />
            <label
              className={styles.tabLabel}
              htmlFor={`${formId}-radio-${tab}-${index}`}
            >
              {tab}
            </label>
          </div>
        ))}
        <span
          className={styles.glider}
          style={{
            transform: `translateX(${gliderTranslation})`,
            width: `calc(${tabWidth}% - ${gliderOffset}px)`,
          }}
        />
      </fieldset>
    </form>
  )
}
