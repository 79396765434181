import { TextFormats } from 'components/Text'
import { classNamesFsExclude } from 'utils/css'

export const title = 'Activity Updates'

export type TrendPreview = {
  accessibilityLabel: string
  body: TextFormats
  heading: TextFormats
}

export const shortTrendPreview: TrendPreview = {
  accessibilityLabel: 'Short Term Trend',
  body: [
    { content: 'You have vaped ' },
    { className: classNamesFsExclude(), content: '%{trend}', tagName: 'span' },
    { content: ' last week.' },
  ],
  heading: { content: 'This Week' },
}

export const longTrendPreview: TrendPreview = {
  accessibilityLabel: 'Long Term Trend',
  body: [
    { content: 'You have vaped ' },
    { className: classNamesFsExclude(), content: '%{trend}', tagName: 'span' },
    { content: ' usual.' },
  ],
  heading: [
    { content: 'Past ' },
    { className: classNamesFsExclude(), content: '%{weeks}', tagName: 'span' },
    { content: ' Weeks' },
  ],
}
