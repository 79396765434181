/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** Web App Screens & Click Events */
export namespace WebAppScreens {

  export enum Name {
    Welcome = "Welcome",
    AndroidRedirect = "AndroidRedirect",
    BluefyRedirect = "BluefyRedirect",
    ChromeRedirect = "ChromeRedirect",
    IosRedirect = "IosRedirect",
    UnsupportedDevice = "UnsupportedDevice",
    UnsupportedRegion = "UnsupportedRegion",
    Greeting = "Greeting",
    UpdateAlertStep = "UpdateAlertStep",
    LocateSerialNumberStep = "LocateSerialNumberStep",
    InitiatePairingModeStep = "InitiatePairingModeStep",
    ConfirmDeviceStep = "ConfirmDeviceStep",
    InsertPodStep = "InsertPodStep",
    ShakeDeviceStep = "ShakeDeviceStep",
    ShakeDeviceRescueStep = "ShakeDeviceRescueStep",
    ShakeDeviceInterruptStep = "ShakeDeviceInterruptStep",
    SelectDeviceStepChrome = "SelectDeviceStep_Chrome",
    SelectDeviceStepBluefy = "SelectDeviceStep_Bluefy",
    LegacySelectDeviceStepChrome = "LegacySelectDeviceStep_Chrome",
    LegacySelectDeviceStepBluefy = "LegacySelectDeviceStep_Bluefy",
    ConnectingDeviceStep = "ConnectingDeviceStep",
    PairSuccessNameDeviceStep = "PairSuccessNameDeviceStep",
    ReconnectRescueSuccessStep = "ReconnectRescueSuccessStep",
    BluetoothUnavailableStep = "BluetoothUnavailableStep",
    TroubleshootSelectDeviceStep = "TroubleshootSelectDeviceStep",
    PairingFailedStep = "PairingFailedStep",
    CancelInitiatePairingModeStep = "CancelInitiatePairingModeStep",
    CancelLocateSerialNumberStep = "CancelLocateSerialNumberStep",
    CancelShakeDeviceStep = "CancelShakeDeviceStep",
    CancelShakeDeviceRescueStep = "CancelShakeDeviceRescueStep",
    CancelInsertPodStep = "CancelInsertPodStep",
    CloudSyncOptInStep = "CloudSyncOptInStep",
    ChargeDeviceStep = "ChargeDeviceStep",
    StartUpdateStep = "StartUpdateStep",
    DownloadingStepDesktop = "DownloadingStep_Desktop",
    DownloadingStepMobile = "DownloadingStep_Mobile",
    UpdatingStepDesktop = "UpdatingStep_Desktop",
    UpdatingStepMobile = "UpdatingStep_Mobile",
    RebootingStepDesktop = "RebootingStep_Desktop",
    RebootingStepMobile = "RebootingStep_Mobile",
    UpdateCompleteStepDesktop = "UpdateCompleteStep_Desktop",
    UpdateCompleteStepMobile = "UpdateCompleteStep_Mobile",
    InterruptedStep = "InterruptedStep",
    UsageInsights = "UsageInsights",
    LongTermTrends = "LongTermTrends",
    ShortTermTrends = "ShortTermTrends",
    Settings = "Settings",
    Home = "Home",
  }

  /** Indicates whether the user has their first full week of data in order to display all insights. */
  export enum UsageInsightsState {
    HasFirstWeek = "has_first_week",
    NoFirstWeek = "no_first_week",
  }

  /** Indicate which activity updates are visible. */
  export enum ActivityUpdatesState {
    NoActivityUpdates = "no_activity_updates",
    LongTermUpdatesOnly = "long_term_updates_only",
    ShortTermUpdatesOnly = "short_term_updates_only",
    BothActivityUpdates = "both_activity_updates",
  }

  /** Indicates the version of the pairing flow being used. */
  export enum PairingFlow {
    PodPullPair = "pod_pull_pair",
    ShakeToPair = "shake_to_pair",
  }

  export const WelcomeEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "Welcome",
    })
  }

  export namespace Welcome {
    export enum ViewClicked {
      SignUpLogIn = "SignUpLogIn",
      TryAgain = "TryAgain",
    }

    export const ClickSignUpLogIn = (): Event => {
      const viewClicked = ViewClicked.SignUpLogIn
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Welcome",
      })
    }

    export const ClickTryAgain = (): Event => {
      const viewClicked = ViewClicked.TryAgain
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Welcome",
      })
    }
  }

  export const AndroidRedirectEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "AndroidRedirect",
    })
  }

  export namespace AndroidRedirect {
    export enum ViewClicked {
      DownloadFromGooglePlay = "DownloadFromGooglePlay",
    }

    export const ClickDownloadFromGooglePlay = (): Event => {
      const viewClicked = ViewClicked.DownloadFromGooglePlay
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "AndroidRedirect",
      })
    }
  }

  export const BluefyRedirectEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "BluefyRedirect",
    })
  }

  export namespace BluefyRedirect {
    export enum ViewClicked {
      DownloadFromTheAppStore = "DownloadFromTheAppStore",
    }

    export const ClickDownloadFromTheAppStore = (): Event => {
      const viewClicked = ViewClicked.DownloadFromTheAppStore
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "BluefyRedirect",
      })
    }
  }

  export const ChromeRedirectEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ChromeRedirect",
    })
  }

  export namespace ChromeRedirect {
    export enum ViewClicked {
      DownloadFromGoogleChrome = "DownloadFromGoogleChrome",
    }

    export const ClickDownloadFromGoogleChrome = (): Event => {
      const viewClicked = ViewClicked.DownloadFromGoogleChrome
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ChromeRedirect",
      })
    }
  }

  export const IosRedirectEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "IosRedirect",
    })
  }

  export namespace IosRedirect {
    export enum ViewClicked {
      Copy = "Copy",
      DownloadFromTheAppStore = "DownloadFromTheAppStore",
    }

    export const ClickCopy = (): Event => {
      const viewClicked = ViewClicked.Copy
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "IosRedirect",
      })
    }

    export const ClickDownloadFromTheAppStore = (): Event => {
      const viewClicked = ViewClicked.DownloadFromTheAppStore
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "IosRedirect",
      })
    }
  }

  export const UnsupportedDeviceEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UnsupportedDevice",
    })
  }

  export namespace UnsupportedDevice {
    export enum ViewClicked {
      Android = "Android",
      IPhoneAndIPad = "IPhoneAndIPad",
      Desktop = "Desktop",
    }

    export const ClickAndroid = (): Event => {
      const viewClicked = ViewClicked.Android
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UnsupportedDevice",
      })
    }

    export const ClickIPhoneAndIPad = (): Event => {
      const viewClicked = ViewClicked.IPhoneAndIPad
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UnsupportedDevice",
      })
    }

    export const ClickDesktop = (): Event => {
      const viewClicked = ViewClicked.Desktop
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UnsupportedDevice",
      })
    }
  }

  type UnsupportedRegionEventProps = {
    accessAttemptRegion: string
  }

  export const UnsupportedRegionEvent = ({
    accessAttemptRegion,
  }: UnsupportedRegionEventProps): Event => {
    const properties: Record<string, any> = {
      access_attempt_region: accessAttemptRegion,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UnsupportedRegion",
    })
  }

  export const GreetingEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "Greeting",
    })
  }

  export namespace Greeting {
    export enum ViewClicked {
      GetStarted = "GetStarted",
      SignOut = "SignOut",
    }

    export const ClickGetStarted = (): Event => {
      const viewClicked = ViewClicked.GetStarted
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Greeting",
      })
    }

    export const ClickSignOut = (): Event => {
      const viewClicked = ViewClicked.SignOut
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Greeting",
      })
    }
  }

  type UpdateAlertStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const UpdateAlertStepEvent = ({
    pairingFlow,
  }: UpdateAlertStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UpdateAlertStep",
    })
  }

  export namespace UpdateAlertStep {
    export enum ViewClicked {
      Continue = "Continue",
    }

    export const ClickContinue = (): Event => {
      const viewClicked = ViewClicked.Continue
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UpdateAlertStep",
      })
    }
  }

  type LocateSerialNumberStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const LocateSerialNumberStepEvent = ({
    pairingFlow,
  }: LocateSerialNumberStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "LocateSerialNumberStep",
    })
  }

  export namespace LocateSerialNumberStep {
    export enum ViewClicked {
      Next = "Next",
      Cancel = "Cancel",
    }

    export const ClickNext = (): Event => {
      const viewClicked = ViewClicked.Next
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "LocateSerialNumberStep",
      })
    }

    export const ClickCancel = (): Event => {
      const viewClicked = ViewClicked.Cancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "LocateSerialNumberStep",
      })
    }
  }

  type InitiatePairingModeStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const InitiatePairingModeStepEvent = ({
    pairingFlow,
  }: InitiatePairingModeStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "InitiatePairingModeStep",
    })
  }

  export namespace InitiatePairingModeStep {
    export enum ViewClicked {
      Next = "Next",
      Cancel = "Cancel",
    }

    export const ClickNext = (): Event => {
      const viewClicked = ViewClicked.Next
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "InitiatePairingModeStep",
      })
    }

    export const ClickCancel = (): Event => {
      const viewClicked = ViewClicked.Cancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "InitiatePairingModeStep",
      })
    }
  }

  type ConfirmDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ConfirmDeviceStepEvent = ({
    pairingFlow,
  }: ConfirmDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ConfirmDeviceStep",
    })
  }

  type InsertPodStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const InsertPodStepEvent = ({
    pairingFlow,
  }: InsertPodStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "InsertPodStep",
    })
  }

  export namespace InsertPodStep {
    export enum ViewClicked {
      Next = "Next",
      Cancel = "Cancel",
    }

    export const ClickNext = (): Event => {
      const viewClicked = ViewClicked.Next
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "InsertPodStep",
      })
    }

    export const ClickCancel = (): Event => {
      const viewClicked = ViewClicked.Cancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "InsertPodStep",
      })
    }
  }

  type ShakeDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ShakeDeviceStepEvent = ({
    pairingFlow,
  }: ShakeDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ShakeDeviceStep",
    })
  }

  export namespace ShakeDeviceStep {
    export enum ViewClicked {
      ISeeTheBlueLight = "ISeeTheBlueLight",
      Cancel = "Cancel",
    }

    export const ClickISeeTheBlueLight = (): Event => {
      const viewClicked = ViewClicked.ISeeTheBlueLight
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShakeDeviceStep",
      })
    }

    export const ClickCancel = (): Event => {
      const viewClicked = ViewClicked.Cancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShakeDeviceStep",
      })
    }
  }

  type ShakeDeviceRescueStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ShakeDeviceRescueStepEvent = ({
    pairingFlow,
  }: ShakeDeviceRescueStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ShakeDeviceRescueStep",
    })
  }

  export namespace ShakeDeviceRescueStep {
    export enum ViewClicked {
      Next = "Next",
      Cancel = "Cancel",
    }

    export const ClickNext = (): Event => {
      const viewClicked = ViewClicked.Next
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShakeDeviceRescueStep",
      })
    }

    export const ClickCancel = (): Event => {
      const viewClicked = ViewClicked.Cancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShakeDeviceRescueStep",
      })
    }
  }

  type ShakeDeviceInterruptStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ShakeDeviceInterruptStepEvent = ({
    pairingFlow,
  }: ShakeDeviceInterruptStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ShakeDeviceInterruptStep",
    })
  }

  export namespace ShakeDeviceInterruptStep {
    export enum ViewClicked {
      ISeeTheBlueLight = "ISeeTheBlueLight",
    }

    export const ClickISeeTheBlueLight = (): Event => {
      const viewClicked = ViewClicked.ISeeTheBlueLight
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShakeDeviceInterruptStep",
      })
    }
  }

  type SelectDeviceStepChromeEventProps = {
    pairingFlow: PairingFlow
  }

  export const SelectDeviceStepChromeEvent = ({
    pairingFlow,
  }: SelectDeviceStepChromeEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "SelectDeviceStep_Chrome",
    })
  }

  export namespace SelectDeviceStepChrome {
    export enum ViewClicked {
      Retry = "Retry",
    }

    export const ClickRetry = (): Event => {
      const viewClicked = ViewClicked.Retry
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SelectDeviceStep_Chrome",
      })
    }
  }

  type SelectDeviceStepBluefyEventProps = {
    pairingFlow: PairingFlow
  }

  export const SelectDeviceStepBluefyEvent = ({
    pairingFlow,
  }: SelectDeviceStepBluefyEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "SelectDeviceStep_Bluefy",
    })
  }

  export namespace SelectDeviceStepBluefy {
    export enum ViewClicked {
      Retry = "Retry",
    }

    export const ClickRetry = (): Event => {
      const viewClicked = ViewClicked.Retry
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SelectDeviceStep_Bluefy",
      })
    }
  }

  type LegacySelectDeviceStepChromeEventProps = {
    pairingFlow: PairingFlow
  }

  export const LegacySelectDeviceStepChromeEvent = ({
    pairingFlow,
  }: LegacySelectDeviceStepChromeEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "LegacySelectDeviceStep_Chrome",
    })
  }

  export namespace LegacySelectDeviceStepChrome {
    export enum ViewClicked {
      Retry = "Retry",
    }

    export const ClickRetry = (): Event => {
      const viewClicked = ViewClicked.Retry
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "LegacySelectDeviceStep_Chrome",
      })
    }
  }

  type LegacySelectDeviceStepBluefyEventProps = {
    pairingFlow: PairingFlow
  }

  export const LegacySelectDeviceStepBluefyEvent = ({
    pairingFlow,
  }: LegacySelectDeviceStepBluefyEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "LegacySelectDeviceStep_Bluefy",
    })
  }

  export namespace LegacySelectDeviceStepBluefy {
    export enum ViewClicked {
      Retry = "Retry",
    }

    export const ClickRetry = (): Event => {
      const viewClicked = ViewClicked.Retry
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "LegacySelectDeviceStep_Bluefy",
      })
    }
  }

  type ConnectingDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ConnectingDeviceStepEvent = ({
    pairingFlow,
  }: ConnectingDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ConnectingDeviceStep",
    })
  }

  type PairSuccessNameDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const PairSuccessNameDeviceStepEvent = ({
    pairingFlow,
  }: PairSuccessNameDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "PairSuccessNameDeviceStep",
    })
  }

  export namespace PairSuccessNameDeviceStep {
    export enum ViewClicked {
      Done = "Done",
      Skip = "Skip",
    }

    export const ClickDone = (): Event => {
      const viewClicked = ViewClicked.Done
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "PairSuccessNameDeviceStep",
      })
    }

    export const ClickSkip = (): Event => {
      const viewClicked = ViewClicked.Skip
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "PairSuccessNameDeviceStep",
      })
    }
  }

  type ReconnectRescueSuccessStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const ReconnectRescueSuccessStepEvent = ({
    pairingFlow,
  }: ReconnectRescueSuccessStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ReconnectRescueSuccessStep",
    })
  }

  type BluetoothUnavailableStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const BluetoothUnavailableStepEvent = ({
    pairingFlow,
  }: BluetoothUnavailableStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "BluetoothUnavailableStep",
    })
  }

  export namespace BluetoothUnavailableStep {
    export enum ViewClicked {
      TryPairingAgain = "TryPairingAgain",
      Exit = "Exit",
    }

    export const ClickTryPairingAgain = (): Event => {
      const viewClicked = ViewClicked.TryPairingAgain
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "BluetoothUnavailableStep",
      })
    }

    export const ClickExit = (): Event => {
      const viewClicked = ViewClicked.Exit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "BluetoothUnavailableStep",
      })
    }
  }

  type TroubleshootSelectDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const TroubleshootSelectDeviceStepEvent = ({
    pairingFlow,
  }: TroubleshootSelectDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "TroubleshootSelectDeviceStep",
    })
  }

  export namespace TroubleshootSelectDeviceStep {
    export enum ViewClicked {
      IDidNotSeeMyDevice = "IDidNotSeeMyDevice",
      RetryPairing = "RetryPairing",
      Exit = "Exit",
    }

    export const ClickIDidNotSeeMyDevice = (): Event => {
      const viewClicked = ViewClicked.IDidNotSeeMyDevice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "TroubleshootSelectDeviceStep",
      })
    }

    export const ClickRetryPairing = (): Event => {
      const viewClicked = ViewClicked.RetryPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "TroubleshootSelectDeviceStep",
      })
    }

    export const ClickExit = (): Event => {
      const viewClicked = ViewClicked.Exit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "TroubleshootSelectDeviceStep",
      })
    }
  }

  type PairingFailedStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const PairingFailedStepEvent = ({
    pairingFlow,
  }: PairingFailedStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "PairingFailedStep",
    })
  }

  export namespace PairingFailedStep {
    export enum ViewClicked {
      GiveItAnotherTry = "GiveItAnotherTry",
      Exit = "Exit",
    }

    export const ClickGiveItAnotherTry = (): Event => {
      const viewClicked = ViewClicked.GiveItAnotherTry
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "PairingFailedStep",
      })
    }

    export const ClickExit = (): Event => {
      const viewClicked = ViewClicked.Exit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "PairingFailedStep",
      })
    }
  }

  type CancelInitiatePairingModeStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CancelInitiatePairingModeStepEvent = ({
    pairingFlow,
  }: CancelInitiatePairingModeStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CancelInitiatePairingModeStep",
    })
  }

  export namespace CancelInitiatePairingModeStep {
    export enum ViewClicked {
      KeepPairing = "KeepPairing",
      StopPairingAndExit = "StopPairingAndExit",
    }

    export const ClickKeepPairing = (): Event => {
      const viewClicked = ViewClicked.KeepPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelInitiatePairingModeStep",
      })
    }

    export const ClickStopPairingAndExit = (): Event => {
      const viewClicked = ViewClicked.StopPairingAndExit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelInitiatePairingModeStep",
      })
    }
  }

  type CancelLocateSerialNumberStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CancelLocateSerialNumberStepEvent = ({
    pairingFlow,
  }: CancelLocateSerialNumberStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CancelLocateSerialNumberStep",
    })
  }

  export namespace CancelLocateSerialNumberStep {
    export enum ViewClicked {
      KeepPairing = "KeepPairing",
      StopPairingAndExit = "StopPairingAndExit",
    }

    export const ClickKeepPairing = (): Event => {
      const viewClicked = ViewClicked.KeepPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelLocateSerialNumberStep",
      })
    }

    export const ClickStopPairingAndExit = (): Event => {
      const viewClicked = ViewClicked.StopPairingAndExit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelLocateSerialNumberStep",
      })
    }
  }

  type CancelShakeDeviceStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CancelShakeDeviceStepEvent = ({
    pairingFlow,
  }: CancelShakeDeviceStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CancelShakeDeviceStep",
    })
  }

  export namespace CancelShakeDeviceStep {
    export enum ViewClicked {
      KeepPairing = "KeepPairing",
      StopPairingAndExit = "StopPairingAndExit",
    }

    export const ClickKeepPairing = (): Event => {
      const viewClicked = ViewClicked.KeepPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelShakeDeviceStep",
      })
    }

    export const ClickStopPairingAndExit = (): Event => {
      const viewClicked = ViewClicked.StopPairingAndExit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelShakeDeviceStep",
      })
    }
  }

  type CancelShakeDeviceRescueStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CancelShakeDeviceRescueStepEvent = ({
    pairingFlow,
  }: CancelShakeDeviceRescueStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CancelShakeDeviceRescueStep",
    })
  }

  export namespace CancelShakeDeviceRescueStep {
    export enum ViewClicked {
      KeepPairing = "KeepPairing",
      StopPairingAndExit = "StopPairingAndExit",
    }

    export const ClickKeepPairing = (): Event => {
      const viewClicked = ViewClicked.KeepPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelShakeDeviceRescueStep",
      })
    }

    export const ClickStopPairingAndExit = (): Event => {
      const viewClicked = ViewClicked.StopPairingAndExit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelShakeDeviceRescueStep",
      })
    }
  }

  type CancelInsertPodStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CancelInsertPodStepEvent = ({
    pairingFlow,
  }: CancelInsertPodStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CancelInsertPodStep",
    })
  }

  export namespace CancelInsertPodStep {
    export enum ViewClicked {
      KeepPairing = "KeepPairing",
      StopPairingAndExit = "StopPairingAndExit",
    }

    export const ClickKeepPairing = (): Event => {
      const viewClicked = ViewClicked.KeepPairing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelInsertPodStep",
      })
    }

    export const ClickStopPairingAndExit = (): Event => {
      const viewClicked = ViewClicked.StopPairingAndExit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CancelInsertPodStep",
      })
    }
  }

  type CloudSyncOptInStepEventProps = {
    pairingFlow: PairingFlow
  }

  export const CloudSyncOptInStepEvent = ({
    pairingFlow,
  }: CloudSyncOptInStepEventProps): Event => {
    const properties: Record<string, any> = {
      pairing_flow: pairingFlow,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "CloudSyncOptInStep",
    })
  }

  export namespace CloudSyncOptInStep {
    export enum ViewClicked {
      TurnOn = "TurnOn",
      MaybeLater = "MaybeLater",
      PrivacyPolicy = "PrivacyPolicy",
    }

    export const ClickTurnOn = (): Event => {
      const viewClicked = ViewClicked.TurnOn
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CloudSyncOptInStep",
      })
    }

    export const ClickMaybeLater = (): Event => {
      const viewClicked = ViewClicked.MaybeLater
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CloudSyncOptInStep",
      })
    }

    export const ClickPrivacyPolicy = (): Event => {
      const viewClicked = ViewClicked.PrivacyPolicy
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "CloudSyncOptInStep",
      })
    }
  }

  export const ChargeDeviceStepEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ChargeDeviceStep",
    })
  }

  export namespace ChargeDeviceStep {
    export enum ViewClicked {
      Ok = "Ok",
    }

    export const ClickOk = (): Event => {
      const viewClicked = ViewClicked.Ok
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ChargeDeviceStep",
      })
    }
  }

  export const StartUpdateStepEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "StartUpdateStep",
    })
  }

  export namespace StartUpdateStep {
    export enum ViewClicked {
      UpdateNow = "UpdateNow",
      Later = "Later",
    }

    export const ClickUpdateNow = (): Event => {
      const viewClicked = ViewClicked.UpdateNow
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "StartUpdateStep",
      })
    }

    export const ClickLater = (): Event => {
      const viewClicked = ViewClicked.Later
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "StartUpdateStep",
      })
    }
  }

  export const DownloadingStepDesktopEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "DownloadingStep_Desktop",
    })
  }

  export const DownloadingStepMobileEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "DownloadingStep_Mobile",
    })
  }

  export const UpdatingStepDesktopEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UpdatingStep_Desktop",
    })
  }

  export const UpdatingStepMobileEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UpdatingStep_Mobile",
    })
  }

  export const RebootingStepDesktopEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "RebootingStep_Desktop",
    })
  }

  export const RebootingStepMobileEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "RebootingStep_Mobile",
    })
  }

  export const UpdateCompleteStepDesktopEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UpdateCompleteStep_Desktop",
    })
  }

  export namespace UpdateCompleteStepDesktop {
    export enum ViewClicked {
      BackToApp = "BackToApp",
    }

    export const ClickBackToApp = (): Event => {
      const viewClicked = ViewClicked.BackToApp
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UpdateCompleteStep_Desktop",
      })
    }
  }

  export const UpdateCompleteStepMobileEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UpdateCompleteStep_Mobile",
    })
  }

  export namespace UpdateCompleteStepMobile {
    export enum ViewClicked {
      BackToApp = "BackToApp",
    }

    export const ClickBackToApp = (): Event => {
      const viewClicked = ViewClicked.BackToApp
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UpdateCompleteStep_Mobile",
      })
    }
  }

  export const InterruptedStepEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "InterruptedStep",
    })
  }

  export namespace InterruptedStep {
    export enum ViewClicked {
      Exit = "Exit",
    }

    export const ClickExit = (): Event => {
      const viewClicked = ViewClicked.Exit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "InterruptedStep",
      })
    }
  }

  type UsageInsightsEventProps = {
    activityUpdatesState: ActivityUpdatesState
    usageInsightsState: UsageInsightsState
  }

  export const UsageInsightsEvent = ({
    activityUpdatesState,
    usageInsightsState,
  }: UsageInsightsEventProps): Event => {
    const properties: Record<string, any> = {
      activity_updates_state: activityUpdatesState,
      usage_insights_state: usageInsightsState,
    }

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "UsageInsights",
    })
  }

  export namespace UsageInsights {
    export enum ViewClicked {
      /** Insights Section button to show/hide Activity Updates. */
      ActivityUpdates = "ActivityUpdates",
      /** User clicked on the long term trend preview. */
      LongTermTrendLink = "LongTermTrendLink",
      /** User clicked on the short term trend preview. */
      ShortTermTrendLink = "ShortTermTrendLink",
      /** Insights Card button to show/hide Puffs So Far. */
      PuffsSoFar = "PuffsSoFar",
      /** Insights Card button to show/hide Battery Consumption. */
      BatteryConsumption = "BatteryConsumption",
      /** Insights Card button to show/hide Pod Consumption. */
      PodConsumption = "PodConsumption",
      /** Insights Card button to show/hide Pod History. */
      PodHistory = "PodHistory",
      /** User changed to the 7 day view of the Pod History Chart. */
      SevenDays_PodHistory = "PodHistory|7Days",
      /** User changed to the 30 day view of the Pod History Chart. */
      ThirtyDays_PodHistory = "PodHistory|30Days",
      /** User changed to the 90 day view of the Pod History Chart. */
      NinetyDays_PodHistory = "PodHistory|90Days",
      /** Insights Card button to show/hide Average Puff Hours. */
      AveragePuffHours = "AveragePuffHours",
      /** Insights Card button to show/hide Average Puff Days. */
      AveragePuffDays = "AveragePuffDays",
      /** Insights Card button to show/hide Puff History. */
      PuffHistory = "PuffHistory",
      /** User changed to the 7 day view of the Puff History Chart. */
      SevenDays_PuffHistory = "PuffHistory|7Days",
      /** User changed to month mode in Puff History Chart. */
      Month_PuffHistory = "PuffHistory|Month",
      /** User paged to an earlier 7 days in Puff History Chart. */
      DatePrior_SevenDays_PuffHistory = "PuffHistory|7Days|DatePrior",
      /** User paged to a later 7 days in Puff History Chart. */
      DateForward_SevenDays_PuffHistory = "PuffHistory|7Days|DateForward",
      /** User paged to an earlier month in Puff History Chart. */
      DatePrior_Month_PuffHistory = "PuffHistory|Month|DatePrior",
      /** User paged to a later month in Puff History Chart. */
      DateForward_Month_PuffHistory = "PuffHistory|Month|DateForward",
      /** User selected turn on automatic backups button on the keep your insights notice. */
      TurnOnAutomaticBackup_KeepInsightsNotice = "KeepInsightsNotice|TurnOnAutomaticBackups",
      /** User closed the keep your insights notice. */
      Close_KeepInsightsNotice = "KeepInsightsNotice|Close",
      /** User linked to the privacy policy via the keep your insights notice. */
      PrivacyPolicy_KeepInsightsNotice = "KeepInsightsNotice|PrivacyPolicy",
      /** User closed the puff algo update notice. */
      Close_PuffAlgoNotice = "PuffAlgoNotice|Close",
    }

    export const ToggleActivityUpdates = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.ActivityUpdates}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickLongTermTrendLink = (): Event => {
      const viewClicked = ViewClicked.LongTermTrendLink
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickShortTermTrendLink = (): Event => {
      const viewClicked = ViewClicked.ShortTermTrendLink
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const TogglePuffsSoFar = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.PuffsSoFar}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ToggleBatteryConsumption = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.BatteryConsumption}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const TogglePodConsumption = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.PodConsumption}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const TogglePodHistory = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.PodHistory}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickSevenDays_PodHistory = (): Event => {
      const viewClicked = ViewClicked.SevenDays_PodHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickThirtyDays_PodHistory = (): Event => {
      const viewClicked = ViewClicked.ThirtyDays_PodHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickNinetyDays_PodHistory = (): Event => {
      const viewClicked = ViewClicked.NinetyDays_PodHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ToggleAveragePuffHours = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.AveragePuffHours}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ToggleAveragePuffDays = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.AveragePuffDays}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const TogglePuffHistory = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.PuffHistory}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickSevenDays_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.SevenDays_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickMonth_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.Month_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickDatePrior_SevenDays_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.DatePrior_SevenDays_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickDateForward_SevenDays_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.DateForward_SevenDays_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickDatePrior_Month_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.DatePrior_Month_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickDateForward_Month_PuffHistory = (): Event => {
      const viewClicked = ViewClicked.DateForward_Month_PuffHistory
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickTurnOnAutomaticBackup_KeepInsightsNotice = (): Event => {
      const viewClicked = ViewClicked.TurnOnAutomaticBackup_KeepInsightsNotice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickClose_KeepInsightsNotice = (): Event => {
      const viewClicked = ViewClicked.Close_KeepInsightsNotice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickPrivacyPolicy_KeepInsightsNotice = (): Event => {
      const viewClicked = ViewClicked.PrivacyPolicy_KeepInsightsNotice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }

    export const ClickClose_PuffAlgoNotice = (): Event => {
      const viewClicked = ViewClicked.Close_PuffAlgoNotice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "UsageInsights",
      })
    }
  }

  export const LongTermTrendsEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "LongTermTrends",
    })
  }

  export namespace LongTermTrends {
    export enum ViewClicked {
      Back = "Back",
    }

    export const ClickBack = (): Event => {
      const viewClicked = ViewClicked.Back
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "LongTermTrends",
      })
    }
  }

  export const ShortTermTrendsEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ShortTermTrends",
    })
  }

  export namespace ShortTermTrends {
    export enum ViewClicked {
      Back = "Back",
    }

    export const ClickBack = (): Event => {
      const viewClicked = ViewClicked.Back
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ShortTermTrends",
      })
    }
  }

  export const SettingsEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "Settings",
    })
  }

  export namespace Settings {
    export enum ViewClicked {
      TellUsHowWeAreDoing = "TellUsHowWeAreDoing",
      ReconnectDevice = "ReconnectDevice",
      ForgetThisDevice = "ForgetThisDevice",
      Disconnect = "Disconnect",
      ConnectANewDevice = "ConnectANewDevice",
      SignOut = "SignOut",
      Faqs = "Faqs",
      TermsOfService = "TermsOfService",
      PrivacyPolicy = "PrivacyPolicy",
      /** Insights Card button to hide Puff History Chart */
      AutomaticBackups = "AutomaticBackups",
    }

    export const ClickTellUsHowWeAreDoing = (): Event => {
      const viewClicked = ViewClicked.TellUsHowWeAreDoing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickReconnectDevice = (): Event => {
      const viewClicked = ViewClicked.ReconnectDevice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickForgetThisDevice = (): Event => {
      const viewClicked = ViewClicked.ForgetThisDevice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickDisconnect = (): Event => {
      const viewClicked = ViewClicked.Disconnect
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickConnectANewDevice = (): Event => {
      const viewClicked = ViewClicked.ConnectANewDevice
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickSignOut = (): Event => {
      const viewClicked = ViewClicked.SignOut
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickFaqs = (): Event => {
      const viewClicked = ViewClicked.Faqs
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickTermsOfService = (): Event => {
      const viewClicked = ViewClicked.TermsOfService
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ClickPrivacyPolicy = (): Event => {
      const viewClicked = ViewClicked.PrivacyPolicy
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }

    export const ToggleAutomaticBackups = ({
      enabled,
    }: { enabled: boolean }): Event => {
      const direction = enabled ? 'On' : 'Off'
      const viewClicked = `${ViewClicked.AutomaticBackups}|${direction}`
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Settings",
      })
    }
  }

  export const HomeEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "Home",
    })
  }

  export namespace Home {
    export enum ViewClicked {
      DeviceConnectedStateLock = "DeviceConnectedState|Lock",
      DeviceConnectedStateRing = "DeviceConnectedState|Ring",
      DeviceDisconnectedStateReconnect = "DeviceDisconnectedState|Reconnect",
      DeviceDisconnectedWarningStateReconnect = "DeviceDisconnectedWarningState|Reconnect",
      DeviceNewStateConnect = "DeviceNewState|Connect",
      DeviceConnectingStateCancel = "DeviceConnectingState|Cancel",
      UsageInsightsViewAll = "UsageInsights|ViewAll",
      YourOrdersViewAll = "YourOrders|ViewAll",
      YourOrdersGoToJuulCom = "YourOrders|GoToJuulCom",
      YourOrdersLearnMore = "YourOrders|LearnMore",
      YourOrdersFindAStoreNearMe = "YourOrders|FindAStoreNearMe",
    }

    export const ClickDeviceConnectedStateLock = (): Event => {
      const viewClicked = ViewClicked.DeviceConnectedStateLock
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickDeviceConnectedStateRing = (): Event => {
      const viewClicked = ViewClicked.DeviceConnectedStateRing
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickDeviceDisconnectedStateReconnect = (): Event => {
      const viewClicked = ViewClicked.DeviceDisconnectedStateReconnect
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickDeviceDisconnectedWarningStateReconnect = (): Event => {
      const viewClicked = ViewClicked.DeviceDisconnectedWarningStateReconnect
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickDeviceNewStateConnect = (): Event => {
      const viewClicked = ViewClicked.DeviceNewStateConnect
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickDeviceConnectingStateCancel = (): Event => {
      const viewClicked = ViewClicked.DeviceConnectingStateCancel
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickUsageInsightsViewAll = (): Event => {
      const viewClicked = ViewClicked.UsageInsightsViewAll
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickYourOrdersViewAll = (): Event => {
      const viewClicked = ViewClicked.YourOrdersViewAll
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickYourOrdersGoToJuulCom = (): Event => {
      const viewClicked = ViewClicked.YourOrdersGoToJuulCom
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickYourOrdersLearnMore = (): Event => {
      const viewClicked = ViewClicked.YourOrdersLearnMore
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }

    export const ClickYourOrdersFindAStoreNearMe = (): Event => {
      const viewClicked = ViewClicked.YourOrdersFindAStoreNearMe
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "Home",
      })
    }
  }
}
